<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo rimborso</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Customer list, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showCustomer" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca cliente" v-model="searchQuery" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="customer in searchedCustomers"
                                    :key="customer.customers_id"
                                    @click="setSelectedCustomer(customer)"
                                    class="customer"
                                    :class="setActiveCustomer(customer, selectedCustomer)"
                                >
                                    {{ customer.customers_full_name ?
                                        customer.customers_full_name :
                                            customer.customers_company ?
                                            customer.customers_company : `${customer.customers_name} ${customer.customers_last_name}`
                                    }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeCustomerSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="creaRimborso()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection" position="floating">
                                    <span>
                                        Cliente
                                    </span>
                                    <div @click="openCustomerSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedCustomer">
                                        {{
                                            selectedCustomer.customers_full_name
                                        }}
                                    </div>
                                    <div v-else>Nessun cliente selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Cliente non censito -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Cliente (se non censito)
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.cliente_manuale" placeholder="Es. Mario Rossi"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2021"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="rimborso.data"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Luogo -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Partenza
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.luogo" placeholder="Udine, via Roma 25"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Luogo -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Arrivo
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.luogo_arrivo" placeholder="Milano, Corso Como 20"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Motivo -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Motivo
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.motivo" placeholder="Es. incontro con cliente"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Km effettuati -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Km effettuati <small>(Vuoto per calcolo automatico)</small></div>
                                <div class="value">
                                    <ion-input type="text" v-model="rimborso.km" placeholder="Es. 245"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Andata e ritorno -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Andata e ritorno</div>
                                <div class="value">
                                    <ion-checkbox class="ion-margin-end" v-model="rimborso.andata_ritorno"> </ion-checkbox>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions">
                            <button type="submit" class="btn_rimborso" :disabled="sendingData">
                                Inserisci rimborso
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonText,
    IonInput,
    IonCheckbox,
    IonIcon,
} from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";
import { defineComponent, ref, reactive, computed, onMounted } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "@/services/toast";
import apiSettings from "@/services/settings";
import apiClienti from "@/services/clienti";
import apiRimborsi from "@/services/rimborsi";

export default defineComponent({
    name: "ModalNuovoRimborso",
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonText,
        IonInput,
        IonCheckbox,
        IonIcon,
    },
    setup(props, context) {
        //console.log(props.data);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id; //set userID in user field
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const data_viaggio = new Date();
        const rimborso = reactive({
            data: data_viaggio.toISOString(),
            cliente: null,
            cliente_manuale: null,
            motivo: null,
            luogo: null,
            luogo_arrivo: null,
            km: null,
            andata_ritorno: false
        });

        const successResponse = ref(false);

        const showCustomer = ref(false);
        function openCustomerSelection() {
            showCustomer.value = true;
        }
        function closeCustomerSelection() {
            showCustomer.value = false;
        }

        /**
         * Load customer
         */
        const customers = ref([]);
        async function loadCustomer() {
            try {
                const res = await apiClienti.getCustomers();
                if (res.status === 0) {
                    customers.value = res.data;
                } else {
                    openToast("Errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei clienti", "toast_danger");
            }
        }

        const searchQuery = ref("");
        const searchedCustomers = computed(() => {
            const term = searchQuery.value.replace(/ /g, "").toLowerCase();

            return customers.value.filter((cliente) => {
                // Se il campo customers_full_name è presente, lo usiamo per la ricerca
                if (cliente.customers_full_name) {
                    return cliente.customers_full_name
                        .toLowerCase()
                        .replace(/ /g, "")
                        .indexOf(term) != -1;
                }
                // Se customers_full_name non è presente, passiamo a customers_company
                else if (cliente.customers_company) {
                    return cliente.customers_company
                        .toLowerCase()
                        .replace(/ /g, "")
                        .indexOf(term) != -1;
                }
                // Se customers_company non è presente, passiamo a customers_name e customers_last_name
                else if (cliente.customers_name || cliente.customers_last_name) {
                    const fullName = (cliente.customers_name || "") + (cliente.customers_last_name || "");
                    return fullName
                        .toLowerCase()
                        .replace(/ /g, "")
                        .indexOf(term) != -1;
                }
                return false; // Se nessun campo è presente, escludiamo il cliente
            });
        });

        const selectedCustomer = ref(null);
        function setSelectedCustomer(customer) {
            //console.log(customer);
            if (customer) {
                searchQuery.value = "";
                selectedCustomer.value = customer;
                showCustomer.value = false;
                //imposta il cliente sul rimborso
                rimborso.cliente = customer.customers_id;
            }
        }

        const setActiveCustomer = computed(() => {
            return (customer, selectedCustomer) => {
                let className = "";

                if (selectedCustomer) {
                    if (customer.customers_id === selectedCustomer.customers_id) {
                        className = "active_customer";
                    }
                }
                return className;
            };
        });

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Create new spesa
         */
        const sendingData = ref(false);
        async function creaRimborso() {
            // Selezionato cliente o inserito testualmente
            if (!rimborso.cliente && !rimborso.cliente_manuale) {
                openToast("Devi compilare almeno un campo tra cliente e cliente manuale", "toast_danger");
                return;
            }
            //Data obbligatoria
            if (!rimborso.data) {
                openToast("La data è obbligatoria", "toast_danger");
                return;
            }
            if (!rimborso.luogo) {
                openToast("Il luogo di partenza è obbligatorio", "toast_danger");
                return;
            }
            
            if (!rimborso.luogo_arrivo && !rimborso.km) {
                openToast("Devi compilare almeno un campo tra luogo di destinazione e km effettuati", "toast_danger");
                return;
            }

            sendingData.value = true;

            const data = new FormData();
            data.append("rimborsi_km_agente", userID); //logged user
            data.append("rimborsi_km_dipendente", dipendenteID); //logged user
            if (rimborso.cliente) {
                data.append("rimborsi_km_cliente", rimborso.cliente);
            }
            if (rimborso.cliente_manuale) {
                data.append("rimborsi_km_clienti", rimborso.cliente_manuale);
            }
            data.append("rimborsi_km_data", moment(rimborso.data).format("YYYY-MM-DD"));
            data.append("rimborsi_km_luogo", rimborso.luogo);
            data.append("rimborsi_km_luogo_arrivo", rimborso.luogo_arrivo);
            if (rimborso.motivo) {
                data.append("rimborsi_km_motivo", rimborso.motivo);
            }
            if (rimborso.km) {
                data.append("rimborsi_km_km", rimborso.km);
            }
            data.append("rimborsi_km_andata_ritorno", rimborso.andata_ritorno);
            data.append("rimborsi_km_fake", "1");

            try {
                const response = await apiRimborsi.saveRimborso(data);

                if (response.data.status !== 0) {
                    openToast(response.data.message, "toast_danger");
                } else {
                    const res = response.data.data;
                    //console.log(res);
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, res[0]);
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la creazione del rimborso", "toast_danger");
            } finally {
                sendingData.value = false;
            }
        }

        /**
         * 
         * ! Load azienda settings to get start address
         * 
         */
        const settings = ref([]);
        async function loadSettings() {
            try {
                const res = await apiSettings.getAziendaSettings();
                if (res.status === 200 && res.data.status === 0) {
                    /* settings.value = res.data.data[0];
                    console.log(settings.value);
                    if() */
                    if (res.data.data.length != 0) {
                        const data = res.data.data[0];
                        rimborso.luogo = (data.settings_company_address || data.settings_company_city) 
                            ? `${data.settings_company_address || ''} ${data.settings_company_city || ''}`.trim()
                            : null;
                    }
                } else {
                    openToast("Non è stato possibile impostare il luogo di partenza in automatico", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Non è stato possibile impostare il luogo di partenza in automatico", "toast_danger");
            }
        }

        onMounted(() => {
            loadSettings();
            loadCustomer();
        });

        return {
            arrowBackOutline,
            rimborso,
            creaRimborso,
            closeModal,
            customers,
            showCustomer,
            openCustomerSelection,
            closeCustomerSelection,
            searchQuery,
            searchedCustomers,
            setSelectedCustomer,
            selectedCustomer,
            setActiveCustomer,
            sendingData
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}
ion-datetime,
ion-select {
    max-width: 100%;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    --padding-start: 12px;
    --padding-end: 12px;
    --padding-left: 12px;
    --padding-right: 12px;
    --placeholder-color: #00000080;
}
ion-select::part(icon) {
    opacity: 1;
    color: #086fa3;
}

ion-input,
ion-textarea {
    --placeholder-color: #00000080;
    --padding-start: 12px !important;
    --padding-end: 12px !important;
    --padding-left: 12px !important;
    --padding-right: 12px !important;
    /* border: 1px solid #d1d5db; */
    border: 1px solid #0000001a;
    border-radius: 6px;
    margin-top: 0;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}
ion-button {
    --color: #ffffff;
}
ion-checkbox {
    --border-width: 1px;
    --border-color: #d1d5db;
    --background-checked: #0087ca;
    --border-color-checked: #d1d5db;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    background: #f2f2f2;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 2px 5px 0px #00000040;
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #000000;
}
.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

.btn_rimborso {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    border-radius: 8px;
    text-align: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.btn_rimborso:disabled {
    touch-action: none;
    opacity: .55;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}
</style>
